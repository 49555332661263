"use strict";
const route = (event) => {
    event = event || window.event;
    event.preventDefault();
    window.history.pushState({}, "", event.target.href);
};
const routes = {
    "/": "/index.html",
    dashboard: "/index.html",
};
globalThis.route = route;
